import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent } from "firebase/analytics"

import { useEffect, useState } from "react"
import styled from "styled-components"
import Countdown from "react-countdown"
import { Button, CircularProgress, Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"

import { LAMPORTS_PER_SOL } from "@solana/web3.js"

import { useAnchorWallet } from "@solana/wallet-adapter-react"
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui"

import { awaitTransactionSignatureConfirmation, getCandyMachineState, mintTokens, shortenAddress } from "../scripts/candy-machine"

const firebaseConfig = {
	apiKey: "AIzaSyAIbvxiXkP7nkE8RpF_9fC21uO-jT-E_Mw",
	authDomain: "solseats-76da2.firebaseapp.com",
	projectId: "solseats-76da2",
	storageBucket: "solseats-76da2.appspot.com",
	messagingSenderId: "858222541541",
	appId: "1:858222541541:web:4ee60a2369ec0a7332720c",
	measurementId: "G-Y8B88V7Z9W",
}
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const ConnectButton = styled(WalletDialogButton)``

const InitiateMintButton = styled(Button)`` // add your styles here

const MintButton = (props) => {
	const [balance, setBalance] = useState()
	const [isActive, setIsActive] = useState(false) // true when countdown completes
	const [isSoldOut, setIsSoldOut] = useState(false) // true when items remaining is zero
	const [isMinting, setIsMinting] = useState(false) // true when user got to press MINT

	const [itemsAvailable, setItemsAvailable] = useState(0)
	const [itemsRedeemed, setItemsRedeemed] = useState(0)
	const [itemsRemaining, setItemsRemaining] = useState(0)

	const [alertState, setAlertState] = useState({
		open: false,
		message: "",
		severity: undefined,
	})

	const [startDate, setStartDate] = useState(new Date(props.startDateSeed))

	const wallet = useAnchorWallet()
	const [candyMachine, setCandyMachine] = useState()

	const refreshCandyMachineState = () => {
		;(async () => {
			if (!wallet) return

			const { candyMachine, goLiveDate, itemsAvailable, itemsRemaining, itemsRedeemed } = await getCandyMachineState(wallet, props.candyMachineId, props.connection)

			setItemsAvailable(itemsAvailable)
			setItemsRemaining(itemsRemaining)
			setItemsRedeemed(itemsRedeemed)

			setIsSoldOut(itemsRemaining === 0 || itemsRedeemed >= 700)
			setStartDate(goLiveDate)
			setCandyMachine(candyMachine)

			logEvent(analytics, "add_payment_info")
		})()
	}

	const onMint = async () => {
		try {
			setIsMinting(true)
			if (wallet && candyMachine && candyMachine.program) {
				const mintTxId = await mintTokens(candyMachine, props.config, wallet.publicKey, props.treasury)

				const status = await awaitTransactionSignatureConfirmation(mintTxId, props.txTimeout, props.connection, "singleGossip", false)

				if (!status?.err) {
					setAlertState({
						open: true,
						message: "Congratulations! Mint succeeded!",
						severity: "success",
					})
				} else {
					setAlertState({
						open: true,
						message: "Mint failed! Please try again!",
						severity: "error",
					})
				}
			}
		} catch (error) {
			// TODO: blech:
			console.log(error)
			let message = error.msg || "Minting failed! Please try again!"
			if (!error.msg) {
				if (error.message.indexOf("0x138")) {
				} else if (error.message.indexOf("0x137")) {
					message = `SOLD OUT!`
				} else if (error.message.indexOf("0x135")) {
					message = `Insufficient funds to mint. Please fund your wallet.`
				}
			} else {
				if (error.code === 311) {
					message = `SOLD OUT!`
					setIsSoldOut(true)
				} else if (error.code === 312) {
					message = `Minting period hasn't started yet.`
				}
			}

			setAlertState({
				open: true,
				message,
				severity: "error",
			})
		} finally {
			if (wallet) {
				const balance = await props.connection.getBalance(wallet.publicKey)
				setBalance(balance / LAMPORTS_PER_SOL)
			}
			setIsMinting(false)
			refreshCandyMachineState()

			logEvent(analytics, "purchase")
		}
	}

	useEffect(() => {
		;(async () => {
			if (wallet) {
				const balance = await props.connection.getBalance(wallet.publicKey)
				setBalance(balance / LAMPORTS_PER_SOL)
			}
		})()
	}, [wallet, props.connection])

	useEffect(refreshCandyMachineState, [wallet, props.candyMachineId, props.connection])

	return (
		<div>
			{wallet ? (
				<h2 className="md:my-12 my-8 font-thin font-body text-2xl text-white">
					<span className="font-medium">
						{Math.min(itemsRedeemed, 700)}/700
					</span>{" "}
					seats taken.{" "}
					{balance ? (
						<span>
							<span className="font-medium">{balance.toFixed(3)} Sol</span> in your wallet.
						</span>
					) : (
						""
					)}
				</h2>
			) : (
				<h2 className="md:my-12 my-8 font-thin font-body text-2xl text-white">
					Minting is live.<span className="font-medium">Take a seat</span>.
				</h2>
			)}

			<div className="flex">
				{!wallet ? (
					<ConnectButton className="h-16 w-64 rounded-md font-medium font-body tracking-widest text-blue-800 hover:opacity-60 hover:blur-sm transition duration-500 ease-in-out bg-white shadow-none hover:bg-white hover:shadow-none">
						CONNECT WALLET &#128274;
					</ConnectButton>
				) : (
					<InitiateMintButton
						className="h-16 w-64 rounded-md font-medium font-body tracking-widest text-blue-800 hover:opacity-60 hover:blur-sm transition duration-500 ease-in-out bg-white shadow-none hover:bg-white hover:shadow-none"
						disabled={isSoldOut || isMinting || !isActive}
						onClick={onMint}
						variant="contained"
					>
						{isSoldOut ? (
							"Sold out"
						) : isActive ? (
							isMinting ? (
								<CircularProgress />
							) : (
								"Mint here"
							)
						) : (
							<Countdown date={startDate} onMount={({ completed }) => completed && setIsActive(true)} onComplete={() => setIsActive(true)} renderer={renderCounter} />
						)}
					</InitiateMintButton>
				)}
			</div>

			<Snackbar open={alertState.open} autoHideDuration={6000} onClose={() => setAlertState({ ...alertState, open: false })}>
				<Alert onClose={() => setAlertState({ ...alertState, open: false })} severity={alertState.severity}>
					{alertState.message}
				</Alert>
			</Snackbar>
		</div>
	)
}

const renderCounter = ({ days, hours, minutes, seconds, completed }) => {
	return (
		<span className="text-white">
			{hours == 0 ? "" : hours + "h, "}
			{minutes == 0 ? "" : minutes + "m, "}
			{seconds + "s"}
		</span>
	)
}

export default MintButton
