import { useMemo } from "react";

import Header from './components/Header';
import Mint from './components/Mint';
import Body from './components/Body';

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider
} from "@solana/wallet-adapter-react";

import { StylesProvider } from "@material-ui/core/styles";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

const treasury = new anchor.web3.PublicKey(
	process.env.REACT_APP_TREASURY_ADDRESS
);

const config = new anchor.web3.PublicKey(
	process.env.REACT_APP_CANDY_MACHINE_CONFIG
);

const candyMachineId = new anchor.web3.PublicKey(
	process.env.REACT_APP_CANDY_MACHINE_ID
);

const network = process.env.REACT_APP_SOLANA_NETWORK;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE, 10);

const txTimeout = 30000;


const App = () => {
	const endpoint = useMemo(() => clusterApiUrl(network), []);

	const wallets = useMemo(
		() => [
			getPhantomWallet(),
			getSlopeWallet(),
			getSolflareWallet(),
			getSolletWallet({ network }),
			getSolletExtensionWallet({ network })
		],
		[]
	);

	return (
		<StylesProvider injectFirst>
			<ConnectionProvider endpoint={endpoint}>
				<WalletProvider wallets={wallets} autoConnect>
					<WalletDialogProvider className="text-white">
						<div className="bg-gradient-to-r from-blue-800 to-blue-400">
							<Header></Header>
							<Mint
								candyMachineId={candyMachineId}
								config={config}
								connection={connection}
								startDate={startDateSeed}
								treasury={treasury}
								txTimeout={txTimeout}
							></Mint>
							<Body></Body>
						</div>
					</WalletDialogProvider>
				</WalletProvider>
			</ConnectionProvider>
		</StylesProvider>
  	);
}

export default App;
