import { useState } from "react"

import twitter from "../images/Twitter.svg"
import discord from "../images/Discord.svg"
import TableLoop from "../images/TableLoop.gif"

import alphabot from "../images/traits/accessory/accessory_alphabot.jpg"
import apple from "../images/traits/accessory/accessory_apple.jpg"
import banana from "../images/traits/accessory/accessory_banana.jpg"
import basketball from "../images/traits/accessory/accessory_basketball.jpg"
import bong from "../images/traits/accessory/accessory_bong.jpg"
import cactus from "../images/traits/accessory/accessory_cactus.jpg"
import cinderblock from "../images/traits/accessory/accessory_cinderblock.jpg"
import flowers from "../images/traits/accessory/accessory_flowers.jpg"
import heels from "../images/traits/accessory/accessory_heels.jpg"
import martini from "../images/traits/accessory/accessory_martini.jpg"
import pepe from "../images/traits/accessory/accessory_pepe.jpg"
import pizza from "../images/traits/accessory/accessory_pizza.jpg"
import shadez from "../images/traits/accessory/accessory_shadez.jpg"
import skateboard from "../images/traits/accessory/accessory_skateboard.jpg"
import waterbottle from "../images/traits/accessory/accessory_waterbottle.jpg"
import weight from "../images/traits/accessory/accessory_weight.jpg"

import bgalpha from "../images/traits/background/background_alpha.jpg"
import bgasphalt from "../images/traits/background/background_asphalt.jpg"
import bgblue from "../images/traits/background/background_blue.jpg"
import bggreen from "../images/traits/background/background_green.jpg"
import bgpaint from "../images/traits/background/background_paintmix.jpg"
import bgpeach from "../images/traits/background/background_peach.jpg"
import bgpurple from "../images/traits/background/background_purple.jpg"
import bgsky from "../images/traits/background/background_sky.jpg"
import bgsol from "../images/traits/background/background_sol.jpg"
import bgspace from "../images/traits/background/background_space.jpg"
import bgtile from "../images/traits/background/background_tile.jpg"
import bgtorn from "../images/traits/background/background_torn.jpg"
import bgvelvet from "../images/traits/background/background_velvet.jpg"
import bgwhite from "../images/traits/background/background_white.jpg"
import bgyellow from "../images/traits/background/background_yellow.jpg"

import baseblue from "../images/traits/base/base_blue.jpg"
import baseconcrete from "../images/traits/base/base_concrete.jpg"
import basegold from "../images/traits/base/base_gold.jpg"
import basegraphite from "../images/traits/base/base_graphite.jpg"
import basegrass from "../images/traits/base/base_grass.jpg"
import basehardwood from "../images/traits/base/base_hardwood.jpg"
import basehole from "../images/traits/base/base_hole.jpg"
import basemarble from "../images/traits/base/base_marble.jpg"
import baseodyssey from "../images/traits/base/base_odyssey.jpg"
import basepeach from "../images/traits/base/base_peach.jpg"
import basepurple from "../images/traits/base/base_purple.jpg"
import baserug from "../images/traits/base/base_rug.jpg"
import baseslime from "../images/traits/base/base_slime.jpg"
import basesol from "../images/traits/base/base_sol.jpg"
import basetile from "../images/traits/base/base_tile.jpg"
import basewater from "../images/traits/base/base_water.jpg"
import basewhite from "../images/traits/base/base_white.jpg"

import balloon from "../images/traits/object/object_balloon.jpg"
import butterfly from "../images/traits/object/object_butterfly.jpg"
import cloud from "../images/traits/object/object_cloud.jpg"
import discoball from "../images/traits/object/object_discoball.jpg"
import drone from "../images/traits/object/object_drone.jpg"
import paperairplane from "../images/traits/object/object_paperairplane.jpg"
import solballoon from "../images/traits/object/object_solballoon.jpg"

import frameblack from "../images/traits/frame/frame_black.jpg"
import framegold from "../images/traits/frame/frame_gold.jpg"
import framesol from "../images/traits/frame/frame_sol.jpg"
import framesteel from "../images/traits/frame/frame_steel.jpg"
import frametoxic from "../images/traits/frame/frame_toxic.jpg"

import handlesblack from "../images/traits/handles/handles_black.jpg"
import handlesgold from "../images/traits/handles/handles_gold.jpg"
import handleswhite from "../images/traits/handles/handles_white.jpg"
import handleswood from "../images/traits/handles/handles_wood.jpg"

import hblack from "../images/traits/hstraps/hstraps_black.jpg"
import hblue from "../images/traits/hstraps/hstraps_blue.jpg"
import hgold from "../images/traits/hstraps/hstraps_gold.jpg"
import hgreen from "../images/traits/hstraps/hstraps_green.jpg"
import hpeach from "../images/traits/hstraps/hstraps_peach.jpg"
import hpurple from "../images/traits/hstraps/hstraps_purple.jpg"
import hred from "../images/traits/hstraps/hstraps_red.jpg"
import htoxic from "../images/traits/hstraps/hstraps_toxic.jpg"
import htransparent from "../images/traits/hstraps/hstraps_transparent.jpg"
import hwhite from "../images/traits/hstraps/hstraps_white.jpg"
import hyellow from "../images/traits/hstraps/hstraps_yellow.jpg"

import vblack from "../images/traits/vstraps/vstraps_black.jpg"
import vblue from "../images/traits/vstraps/vstraps_blue.jpg"
import vgold from "../images/traits/vstraps/vstraps_gold.jpg"
import vgreen from "../images/traits/vstraps/vstraps_green.jpg"
import vpeach from "../images/traits/vstraps/vstraps_peach.jpg"
import vpurple from "../images/traits/vstraps/vstraps_purple.jpg"
import vred from "../images/traits/vstraps/vstraps_red.jpg"
import vtoxic from "../images/traits/vstraps/vstraps_toxic.jpg"
import vtransparent from "../images/traits/vstraps/vstraps_transparent.jpg"
import vwhite from "../images/traits/vstraps/vstraps_white.jpg"
import vyellow from "../images/traits/vstraps/vstraps_yellow.jpg"

const Body = () => {
	const [visibleSection, setVisibleSection] = useState(-1)

	const switchSection = (id) => {
		const height = document.getElementById(id.toString()).clientHeight
		console.log(height + " px")
		document.getElementById("wrapper").style.height = height + "px"
		console.log(document.getElementById("wrapper").style.height)
		setVisibleSection(id)
	}

	setTimeout(() => {
		if (visibleSection == -1) {
			switchSection(0)
		}
	}, 1000)

	return (
		<div>
			<div className="container pt-48 m-auto md:px-24 px-12 z-10">
				<div className="grid lg:grid-cols-3 grid-cols-1 gap-12 items-center">
					<div className="font-body backdrop-filter backdrop-blur shadow-xl py-24 border-2 text-center bg-white bg-opacity-30 rounded-xl hover:bg-opacity-10 transition duration-1000 ease-in-out">
						<p className="tracking-tighter mt-12 font-medium xl:text-9xl text-6xl text-white leading-none">700</p>
						<p className="font-thin text-2xl text-white">Seats</p>
					</div>

					<div className="font-body backdrop-filter backdrop-blur shadow-xl py-24 border-2 text-center bg-white bg-opacity-30 rounded-xl hover:bg-opacity-10 transition duration-1000 ease-in-out">
						<p className="tracking-tighter mt-12 font-medium xl:text-9xl text-6xl text-white leading-none">0.5</p>
						<p className="font-thin text-2xl text-white">Sol</p>
					</div>

					<div className="font-body backdrop-filter backdrop-blur shadow-xl py-24 border-2 text-center bg-white bg-opacity-30 rounded-xl hover:bg-opacity-10 transition duration-1000 ease-in-out">
						<p className="tracking-tighter mt-12 font-medium xl:text-9xl text-6xl text-white leading-none">11.17</p>
						<p className="font-thin text-2xl text-white">Mint</p>
					</div>
				</div>
			</div>
			<div className="bg-black -mt-48 z-10">
				<div className="container pt-96 m-auto sm:px-0 px-12">
					<div className="grid lg:grid-cols-2 grid-cols-1 items-center lg:text-left text-center">
						<div className="">
							<img className="w-full object-center mx-auto mix-blend-screen" src={TableLoop} alt="TheTable"></img>
						</div>
						<div className="lg:px-12 px-0 font-body">
							<p className="font-medium xl:text-9xl text-6xl text-white leading-none">
								<span className="font-light">The</span>
								<span className="font-medium">Table</span>
							</p>

							<p className="mt-12 font-light text-white">
								<span className="font-thin">The</span>
								<span className="font-medium">Table</span> is a space where members can participate, learn, and gain experience from others.
								Sharing alpha, participating in brainstorming sessions, and having an engaging community of likeminded people who down to take a seat.
							</p>
							<p className="font-light text-white mt-6">Join us at the Table.</p>
						</div>
					</div>
				</div>

				<div className="bg-black text-white">
					<div className="container m-auto md:px-24 px-12">
						<div className="pt-48 pb-24 font-body">
							<div className="">
								<div className="w-min bg-black">
									<p className="font-thin xl:text-left text-center 2xl:text-9xl text-6xl text-white leading-none">Artwork</p>
								</div>
								<div className="rounded h-1 bg-gradient-to-r from-black to-white"> </div>
							</div>
						</div>

						<div className="grid lg:grid-cols-4 grid-cols-1 lg:text-left text-center">
							<div className="mb-24">
								<p onClick={() => switchSection(0)} className={"cursor-pointer mb-6 uppercase transition-all duration-500 " + (visibleSection === 0 ? "opacity-100" : "opacity-50")}>
									Base
								</p>

								<p onClick={() => switchSection(1)} className={"cursor-pointer mb-6 uppercase transition-all duration-500 " + (visibleSection === 1 ? "opacity-100" : "opacity-50")}>
									Background
								</p>

								<p onClick={() => switchSection(2)} className={"cursor-pointer mb-6 uppercase transition-all duration-500 " + (visibleSection === 2 ? "opacity-100" : "opacity-50")}>
									Floating object
								</p>

								<p onClick={() => switchSection(3)} className={"cursor-pointer mb-6 uppercase transition-all duration-500 " + (visibleSection === 3 ? "opacity-100" : "opacity-50")}>
									Accessory
								</p>

								<p onClick={() => switchSection(4)} className={"cursor-pointer mb-6 uppercase transition-all duration-500 " + (visibleSection === 4 ? "opacity-100" : "opacity-50")}>
									Horizontal straps
								</p>

								<p onClick={() => switchSection(5)} className={"cursor-pointer mb-6 uppercase transition-all duration-500 " + (visibleSection === 5 ? "opacity-100" : "opacity-50")}>
									Vertical straps
								</p>

								<p onClick={() => switchSection(6)} className={"cursor-pointer mb-6 uppercase transition-all duration-500 " + (visibleSection === 6 ? "opacity-100" : "opacity-50")}>
									Frame
								</p>

								<p onClick={() => switchSection(7)} className={"cursor-pointer uppercase transition-all duration-500 " + (visibleSection === 7 ? "opacity-100" : "opacity-50")}>
									Handles
								</p>
							</div>

							<div id="wrapper" className="relative col-span-3 transition-height duration-500">
								<div className="z-1 select-none pointer-events-none">
									<div
										id="0"
										className={
											"absolute transform transition-all duration-500 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4 items-center overflow-hidden " +
											(visibleSection === 0 ? "opacity-100 translate-y-0" : visibleSection > 1 ? "opacity-0 -translate-y-full" : "opacity-0 translate-y-full")
										}
									>
										<div className="text-center">
											<img className="rounded-md" src={basewhite} alt="Base White"></img>
											<p>WHITE</p>
											<b>12.29%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={baseblue} alt="Base Blue"></img>
											<p>BLUE</p>
											<b>7.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={basepeach} alt="Base Peach"></img>
											<p>PEACH</p>
											<b>7.57%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={basepurple} alt="Base Purple"></img>
											<p>PURPLE</p>
											<b>9.86%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={basegraphite} alt="Base Graphite"></img>
											<p>GRAPHITE</p>
											<b>4.57%</b>
										</div>

										<div className="text-center">
											<img className="rounded-md" src={baseconcrete} alt="Base Concrete"></img>
											<p>CONCRETE</p>
											<b>6.00%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={basehardwood} alt="Base Hardwood"></img>
											<p>HARDWOOD</p>
											<b>6.57%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={basegrass} alt="Base Grass"></img>
											<p>GRASS</p>
											<b>10.29%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={basewater} alt="Base Water"></img>
											<p>WATER</p>
											<b>6.43%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={basemarble} alt="Base Marble"></img>
											<p>MARBLE</p>
											<b>5.43%</b>
										</div>

										<div className="text-center">
											<img className="rounded-md" src={baseslime} alt="Base Slime"></img>
											<p>SLIME</p>
											<b>6.14%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={basehole} alt="Base Hole"></img>
											<p>HOLE</p>
											<b>3.57%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={baseodyssey} alt="Base Odyssey"></img>
											<p>ODYSSEY</p>
											<b>6.00%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={basetile} alt="Base Tile"></img>
											<p>TILE</p>
											<b>3.86%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={basesol} alt="Base Sol"></img>
											<p>$SOL</p>
											<b>2.00%</b>
										</div>

										<div className="text-center">
											<img className="rounded-md" src={basegold} alt="Base Gold"></img>
											<p>GOLD</p>
											<b>1.29%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={baserug} alt="Base Rug"></img>
											<p>RUG</p>
											<b>0.43%</b>
										</div>
									</div>
								</div>

								<div className="z-1 select-none pointer-events-none">
									<div
										id="1"
										className={
											"absolute transform transition-all duration-500 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4 items-center overflow-hidden " +
											(visibleSection === 1 ? "opacity-100 translate-y-0" : visibleSection > 1 ? "opacity-0 -translate-y-full" : "opacity-0 translate-y-full")
										}
									>
										<div className="text-center">
											<img className="rounded-md" src={bgwhite} alt="Background White"></img>
											<p>WHITE</p>
											<b>8.86%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bgblue} alt="Background Blue"></img>
											<p>BLUE</p>
											<b>7.43%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bggreen} alt="Background Green"></img>
											<p>GREEN</p>
											<b>8.29%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bgpeach} alt="Background Peach"></img>
											<p>PEACH</p>
											<b>9.14%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bgyellow} alt="Background Yellow"></img>
											<p>YELLOW</p>
											<b>9.14%</b>
										</div>

										<div className="text-center">
											<img className="rounded-md" src={bgpurple} alt="Background Purple"></img>
											<p>PURPLE</p>
											<b>12.43%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bgalpha} alt="Background Alpha"></img>
											<p>ALPHA</p>
											<b>7.00%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bgsol} alt="Background Sol"></img>
											<p>$SOL</p>
											<b>5.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bgsky} alt="Background Sky"></img>
											<p>SKY</p>
											<b>7.00%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bgasphalt} alt="Background Asphalt"></img>
											<p>ASPHALT</p>
											<b>5.29%</b>
										</div>

										<div className="text-center">
											<img className="rounded-md" src={bgvelvet} alt="Background Velvet"></img>
											<p>VELVET</p>
											<b>3.43%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bgpaint} alt="Background Paint"></img>
											<p>PAINT MIX</p>
											<b>4.86%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bgtile} alt="Background Tile"></img>
											<p>TILE</p>
											<b>4.57%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bgtorn} alt="Background Torn"></img>
											<p>TORN</p>
											<b>4.29%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bgspace} alt="Background Space"></img>
											<p>SPACE</p>
											<b>2.57%</b>
										</div>
									</div>
								</div>

								<div className="z-1 select-none pointer-events-none">
									<div
										id="2"
										className={
											"absolute transform transition-all duration-500 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4 items-center overflow-hidden " +
											(visibleSection === 2 ? "opacity-100 translate-y-0" : visibleSection > 2 ? "opacity-0 -translate-y-full" : "opacity-0 translate-y-full")
										}
									>
										<div className="text-center">
											<img className="rounded-md" src={paperairplane} alt="Paper Airplane"></img>
											<p>PAPER AIRPLANE</p>
											<b>9.57%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={balloon} alt="Balloon"></img>
											<p>BALLOON</p>
											<b>3.86%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={butterfly} alt="Butterfly"></img>
											<p>BUTTERFLY</p>
											<b>11.14%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={drone} alt="Drone"></img>
											<p>DRONE</p>
											<b>7.57%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={discoball} alt="Disco Ball"></img>
											<p>DISCO BALL</p>
											<b>8.43%</b>
										</div>

										<div className="text-center">
											<img className="rounded-md" src={cloud} alt="Cloud"></img>
											<p>CLOUD</p>
											<b>3.14%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={solballoon} alt="Sol Balloon"></img>
											<p>$SOL BALLOON</p>
											<b>1.14%</b>
										</div>
									</div>
								</div>

								<div className="z-1 select-none pointer-events-none">
									<div
										id="3"
										className={
											"absolute transform transition-all duration-500 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4 items-center overflow-hidden " +
											(visibleSection === 3 ? "opacity-100 translate-y-0" : visibleSection > 3 ? "opacity-0 -translate-y-full" : "opacity-0 translate-y-full")
										}
									>
										<div className="text-center">
											<img className="rounded-md" src={cactus} alt="Cactus"></img>
											<p>CACTUS</p>
											<b>6.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={apple} alt="Apple"></img>
											<p>APPLE</p>
											<b>8.43%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={banana} alt="Banana"></img>
											<p>BANANA</p>
											<b>7.57%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={weight} alt="Weight"></img>
											<p>WEIGHT</p>
											<b>5.57%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={bong} alt="Bong"></img>
											<p>BONG</p>
											<b>5.71%</b>
										</div>

										<div className="text-center">
											<img className="rounded-md" src={basketball} alt="Basketball"></img>
											<p>BASKETBALL</p>
											<b>6.00%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={heels} alt="Heels"></img>
											<p>HEELS</p>
											<b>7.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={cinderblock} alt="Cinder Block"></img>
											<p>CINDER BLOCK</p>
											<b>3.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={waterbottle} alt="Water Bottle"></img>
											<p>WATER BOTTLE</p>
											<b>6.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={pizza} alt="Pizza"></img>
											<p>PIZZA</p>
											<b>5.71%</b>
										</div>

										<div className="text-center">
											<img className="rounded-md" src={shadez} alt="Thuggish Glasses"></img>
											<p>THUGGISH GLASSES</p>
											<b>4.14%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={skateboard} alt="Skateboard"></img>
											<p>SKATEBOARD</p>
											<b>4.00%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={flowers} alt="Flowers"></img>
											<p>FLOWERS</p>
											<b>4.00%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={martini} alt="Martini"></img>
											<p>MARTINI</p>
											<b>3.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={alphabot} alt="Alpha Bot"></img>
											<p>ALPHA BOT</p>
											<b>1.57%</b>
										</div>

										<div className="text-center">
											<img className="rounded-md" src={pepe} alt="Space PePe"></img>
											<p>SPACE PePe</p>
											<b>0.14%</b>
										</div>
									</div>
								</div>

								<div className="z-1 select-none pointer-events-none">
									<div
										id="4"
										className={
											"absolute transform transition-all duration-500 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4 items-center overflow-hidden " +
											(visibleSection === 4 ? "opacity-100 translate-y-0" : visibleSection > 4 ? "opacity-0 -translate-y-full" : "opacity-0 translate-y-full")
										}
									>
										<div className="text-center">
											<img className="rounded-md" src={hwhite} alt="White"></img>
											<p>WHITE</p>
											<b>15.00%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={hblue} alt="Blue"></img>
											<p>BLUE</p>
											<b>10.14%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={hgreen} alt="Green"></img>
											<p>GREEN</p>
											<b>10.43%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={hpeach} alt="Peach"></img>
											<p>PEACH</p>
											<b>16.43%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={hyellow} alt="Yellow"></img>
											<p>YELLOW</p>
											<b>11.43%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={hpurple} alt="Purple"></img>
											<p>PURPLE</p>
											<b>11.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={hred} alt="Red"></img>
											<p>RED</p>
											<b>8.86%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={htransparent} alt="Transparent"></img>
											<p>TRANSPARENT</p>
											<b>8.00%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={hblack} alt="Black"></img>
											<p>BLACK</p>
											<b>4.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={hgold} alt="Gold"></img>
											<p>GOLD</p>
											<b>1.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={htoxic} alt="Toxic"></img>
											<p>TOXIC</p>
											<b>1.57%</b>
										</div>
									</div>
								</div>

								<div className="z-1 select-none pointer-events-none">
									<div
										id="5"
										className={
											"absolute transform transition-all duration-500 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4 items-center overflow-hidden " +
											(visibleSection === 5 ? "opacity-100 translate-y-0" : visibleSection > 5 ? "opacity-0 -translate-y-full" : "opacity-0 translate-y-full")
										}
									>
										<div className="text-center">
											<img className="rounded-md" src={vwhite} alt="White"></img>
											<p>WHITE</p>
											<b>17.43%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={vblue} alt="Blue"></img>
											<p>BLUE</p>
											<b>10.00%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={vgreen} alt="Green"></img>
											<p>GREEN</p>
											<b>11.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={vpeach} alt="Peach"></img>
											<p>PEACH</p>
											<b>10.14%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={vyellow} alt="Yellow"></img>
											<p>YELLOW</p>
											<b>9.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={vpurple} alt="Purple"></img>
											<p>PURPLE</p>
											<b>12.43%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={vred} alt="Red"></img>
											<p>RED</p>
											<b>11.86%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={vtransparent} alt="Transparent"></img>
											<p>TRANSPARENT</p>
											<b>8.57%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={vblack} alt="Black"></img>
											<p>BLACK</p>
											<b>5.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={vgold} alt="Gold"></img>
											<p>GOLD</p>
											<b>1.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={vtoxic} alt="Toxic"></img>
											<p>TOXIC</p>
											<b>0.71%</b>
										</div>
									</div>
								</div>

								<div className="z-1 select-none pointer-events-none">
									<div
										id="6"
										className={
											"absolute transform transition-all duration-500 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4 items-center overflow-hidden " +
											(visibleSection === 6 ? "opacity-100 translate-y-0" : visibleSection > 6 ? "opacity-0 -translate-y-full" : "opacity-0 translate-y-full")
										}
									>
										<div className="text-center">
											<img className="rounded-md" src={framesteel} alt="Steel"></img>
											<p>STEEL</p>
											<b>82.14%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={frameblack} alt="Black"></img>
											<p>BLACK</p>
											<b>7.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={framegold} alt="Gold"></img>
											<p>GOLD</p>
											<b>5.14%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={framesol} alt="Sol"></img>
											<p>$SOL</p>
											<b>2.71%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={frametoxic} alt="Toxic"></img>
											<p>TOXIC</p>
											<b>2.29%</b>
										</div>
									</div>
								</div>

								<div className="z-1 select-none pointer-events-none">
									<div
										id="7"
										className={
											"absolute transform transition-all duration-500 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4 items-center overflow-hidden " +
											(visibleSection === 7 ? "opacity-100 translate-y-0" : "opacity-0 translate-y-full")
										}
									>
										<div className="text-center">
											<img className="rounded-md" src={handleswhite} alt="White"></img>
											<p>WHITE</p>
											<b>87.86%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={handlesblack} alt="Black"></img>
											<p>BLACK</p>
											<b>5.14%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={handlesgold} alt="Gold"></img>
											<p>GOLD</p>
											<b>4.43%</b>
										</div>
										<div className="text-center">
											<img className="rounded-md" src={handleswood} alt="Wood"></img>
											<p>WOOD</p>
											<b>2.57%</b>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				
				
				<div className="mt-48 bg-gradient-to-r from-blue-800 to-blue-400 z-10">
					<div className="p-12 flex items-center font-body">
						<p className="text-white">© 2069 SolSeats</p>
						<img
							className="h-10 w-10 float-left ml-auto cursor-pointer hover:opacity-50 transition duration-500 ease-in-out"
							src={twitter}
							alt="twitter"
							onClick={() => window.open("https://twitter.com/SolSeats", "_blank")}
						></img>
						<img
							className="h-10 w-10 float-right ml-6 cursor-pointer hover:opacity-50 transition duration-500 ease-in-out"
							src={discord}
							alt="discord"
							onClick={() => window.open("https://discord.com/invite/XBtP8RDg", "_blank")}
						></img>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Body
