import MintButton from "./MintButton"
import seathero2 from "../images/seathero2.png"

const Mint = (props) => {
	return (
		<div className="">
			<div className="container pt-36 m-auto md:px-24 px-12">
				<div className="grid lg:grid-cols-2 grid-cols-1 items-center">
					<div className="pl-0 lg:pl-24 lg:order-first order-last lg:text-left text-center">
						<h1 className="mt-12 font-thin font-body md:text-9xl text-6xl text-white">
							Sol<span className="font-normal font-body">Seats</span>
						</h1>
						<p className="font-thin font-body mt-12 text-white text-2xl">
						<span className="font-medium">Sold out.</span> All of the seats have been taken.</p>
					</div>
					<div className="">
						<img className="w-full object-center mx-auto" src={seathero2} alt="solSeat"></img>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Mint
