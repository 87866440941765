import twitter from "../images/Twitter.svg"
import discord from "../images/Discord.svg"

const Header = () => {
	return (
		<header>
			<nav className="w-screen mx-auto p-12 z-50 fixed">
				<div className="flex items-center">
					<span
						className="font-thin font-body text-white text-4xl cursor-pointer hover:opacity-50 transition duration-500 ease-in-out"
						onClick={() => window.location("https://solseats.io/")}
					>
						Sol
					</span>
					<span
						className="font-normal font-body text-white text-4xl cursor-pointer hover:opacity-50 transition duration-500 ease-in-out"
						onClick={() => window.location("https://solseats.io/")}
					>
						Seats
					</span>
					<img
						className="h-10 w-10 float-left ml-auto cursor-pointer hover:opacity-50 transition duration-500 ease-in-out"
						src={twitter}
						alt="twitter"
						onClick={() => window.open("https://twitter.com/SolSeats", "_blank")}
					></img>
					<img
						className="h-10 w-10 float-right ml-6 cursor-pointer hover:opacity-50 transition duration-500 ease-in-out"
						src={discord}
						alt="discord"
						onClick={() => window.open("https://discord.gg/jZrh2cuD", "_blank")}
					></img>
				</div>
			</nav>
		</header>
	)
}

export default Header
